// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/SLIDER
// --------------------------------------------------

// VARIABLES
$arrow-color:               inherit;
$arrow-size:                32px;
$menu-point-color:          inherit;
$menu-point-color--active:  $color-brand;
$arrow-padding:							$base-spacing-unit--sm;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/slider";

// --------------------------------------------------

.slider {
	.slider-wrapper > * {
		margin-bottom: $base-spacing-unit--lg;
		//margin-bottom: 0;
	}
	
	.slider-control a {
		width: calc(#{$arrow-size} + #{$arrow-padding} * 2);
		height: calc(#{$arrow-size} + #{$arrow-padding} * 2);
		overflow: hidden;
	}
}
.slider {

	.slider-control {
		.slider-prev,
		.slider-next {
			display: none;
		}
	}
}



// Fullscreen Slider, used in combination with .mod_article.hero
.slider--hero {
	
  height: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  .bx-viewport,
  .content-slider {
    height: 100%;
  }
  
  .bx-wrapper,
  .slider-wrapper,
  .slider-wrapper > *,
  .image_container {
    height: inherit;
  }
  
  .bx-wrapper {
    position: relative;
  }
  
  .image_container {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    
    &.compat-object-fit {
      background-size: cover;
      background-position: center center;
      
      img {
        opacity: 0;
      }
    }
  }
  
  .bx-controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 1001;
  }
  
  .bx-pager {
    
  }
  
  .bx-pager-item {

    display: inline-block;


    margin: 0 0.25rem;
    
    a {
      display: block;
      text-indent: -9999rem;
      background: rgba(0,0,0,0.001);
      border-radius: 50px;
      border: 2px solid #fff;
      width: 1rem;
      height: 1rem;
    }
    
    a.active {
      background: $color-brand;
    }
  }
  
  //Ergänzung aus Portfolio
  
  .caption { 
    @include vertical-align();
    
    position: absolute;
    z-index: 3;
    font-size: 2em;
   text-align: center;
    width: 100%;
    padding: $base-spacing-unit--xs;
 
   
    color: #fff;
    opacity: 0.9;
    visibility: visible;
    transition: all 0.3s 0.1s;
    padding: 0 $base-spacing-unit;
    pointer-events: none; // [1]
  }

}