// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// MIXINS/RESPONSIVE
// --------------------------------------------------

// VARIABLES
// $wrapper-max:   60em; // ~ lg: 960px, xl: 1200px

$wrapper-max:   70em; // ~ lg: 960px, xl: 1200px

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/mixins/responsive";

// --------------------------------------------------

// PUT ADDITONAL RESPONSIVE MIXINS HERE