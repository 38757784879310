//
// NEWS
// --------------------------------------------------

// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/NEWSLETTER
// --------------------------------------------------

// VARIABLES
$newsletter-list-font-family: $headings__font-family;
$newsletter-list-font-weight: $headings__font-weight;

// USED FROM _variables.scss

// $base-spacing-unit--sm

// --------------------------------------------------

@import "../../../nutshell/scss/components/newsletter";

// --------------------------------------------------

.newsletter-subscribe {
  margin-bottom: 0;

  .formbody {
    @if($ie9-support == true) {
      .ie9 & {
        text-align: center;
      }
    }
  }
  
  .text,
  .submit {
    margin-bottom: $base-spacing-unit;
  }
  
  // [1] IE9: calculate input height as line-height is not working as expected
  .text {
    @if($ie9-support == true) {
      .ie9 & {
        width: 62.5%;
        box-sizing: content-box;
        height: #{$base-line-height * 1em}; // [1]
      }
    }
  }
  
  .submit {
    @extend %button;
    @extend button.btn--secondary;
    
    color: $color-brand;
    margin-left: $base-spacing-unit--sm;
  }
}

.newsletter-unsubscribe {
  
  .formbody {
    @if($ie9-support == true) {
      .ie9 & {
        text-align: center;
      }
    }
  }
  
  .text,
  .submit {
    margin-bottom: $base-spacing-unit;
  }
  
  .submit {
    @extend %button;
    @extend button.btn--secondary;
    
    color: $color-brand;
    margin-left: $base-spacing-unit--sm;
  }
  
  // [1] IE9: calculate input height as line-height is not working as expected
  .text {
    @if($ie9-support == true) {
      .ie9 & {
        width: 62.5%;
        box-sizing: content-box;
        height: #{$base-line-height * 1em}; // [1]
      }
    }
  }
}

.newsletter-list {
  li {
    font-family: $newsletter-list-font-family;
    font-weight: $newsletter-list-font-weight;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.newsletter-reader {
	padding-top: $base-spacing-unit;
	padding-bottom: $base-spacing-unit--sm;
}
