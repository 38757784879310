// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// BASE/TYPE
// --------------------------------------------------

// VARIABLES
$color-alert-bg:					lighten($color-brand, 35%);
$color-alert-border:			darken($color-alert-bg, 10%);

$color-code-bg:						$color-gray-light;

// heading
$headings__font-family:		$base-font-family-sans-serif;
$headings__font-weight:		700;

$headlines: (
  h1: (
    sizes: (
      xs: 2.0rem,
      sm: 2.0rem,
      md: 2.25rem,
      lg: 2.25rem,
      xl: 2.5rem
    ),
    line-height: 1.25,
    margin-top: 2rem,
    margin-bottom: 0.5rem
  ),
  h2: (
    sizes: (
      xs: 1.625rem,
      sm: 1.625rem,
      md: 1.750rem,
      lg: 1.750rem,
      xl: 1.875rem
    ),
    line-height: 1.25,
    margin-top: 2rem,
    margin-bottom: 0.5rem
  ),
  h3: (
    sizes: (
      xs: 1.375rem,
      sm: 1.375rem,
      md: 1.500rem,
      lg: 1.500rem,
      xl: 1.500rem
    ),
    line-height: 1.25,
    margin-top: 2rem,
    margin-bottom: 0.5rem
  ),
  h4: (
    sizes: (
      xs: 1.125rem,
      sm: 1.125rem,
      md: 1.250rem,
      lg: 1.250rem,
      xl: 1.250rem
    ),
    line-height: 1.25,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
  h5: (
    sizes: (
      xs: 1rem,
      sm: 1rem,
      md: 1rem,
      lg: 1rem,
      xl: 1rem
    ),
    line-height: 1.25,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
  h6: (
    sizes: (
      xs: 0.875rem,
      sm: 0.875rem,
      md: 0.875rem,
      lg: 0.875rem,
      xl: 0.875rem
    ),
    line-height: 1.25,
    margin-top: 1.5rem,
    margin-bottom: 0.5rem
  ),
) !default;

// paragraph
$paragraph__link-color:             $color-links !default;
$paragraph__link-decoration:        underline;
$paragraph__link-decoration--hover: none;

// code
$code__color:												$color-highlight;
$pre__color-background:							$color-gray;
$pre__color:												$color-text--inverted;

// USED FROM _variables.scss
// $base-spacing-unit
// $base-border-radius

// --------------------------------------------------

@import "../../../nutshell/scss/base/type";

// --------------------------------------------------


// heading sizes
// [1] make sure, that only the first headline inside an element or module 
// is styled this way 
.heading--1 {
	&.ce_headline {
  	@extend %h1;
  }
  
  > #{headings()} { // [1]
		&:first-child {
			@extend %h1; 
		}
	}
}

.heading--2 {
	&.ce_headline {
		@extend %_h2; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h2; 
		}
	}
}

.heading--3 {
	&.ce_headline {
		@extend %_h3; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h3; 
		}
	}
}

.heading--4 {
	&.ce_headline {
		@extend %_h4; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h4; 
		}
	}
}

.heading--5 {
	&.ce_headline {
		@extend %_h5; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h5; 
		}
	}
}

.heading--6 {
	&.ce_headline {
		@extend %_h6; 
	}
	
	> #{headings()} { // [1]
		&:first-child {
			@extend %_h6; 
		}
	}
}

// heading alignment
.heading--left {
  text-align: left;
}

.heading--center {
  text-align: center;
}

.heading--right {
  text-align: right;
}


// Paragraph
p {
  a {
    color: $paragraph__link-color;
    text-decoration: $paragraph__link-decoration;
    
    &:hover {
      text-decoration: $paragraph__link-decoration--hover;
    }
  }
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--attention {
  font-size: 1.25em;
  line-height: 1.6;
}

.text--alert {
	background: $color-alert-bg;
	padding: $base-spacing-unit;
	border-radius: $base-border-radius;
	border: $base-border-width solid $color-alert-border;
	
	#{headings()} {
		@extend %h4;
			
		margin-top: 0 !important;
	}
}

// Lists
ul,
ol {
  padding: 0;
  margin-top: 0;
}

// Blockquotes
blockquote {
  margin: $base-spacing-unit 0;
}

// code
// [1] contao 4.4: used inside ce_code element
code {
	background: $color-code-bg;
	display: inline-block;
	padding-left: 4px;
	padding-right: 4px;
	border-radius: $base-border-radius;
	
	// [1]
	pre & {
		background: none;
		color: inherit;
	}
}
