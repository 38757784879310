// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/LINKS
// --------------------------------------------------

// VARIABLES
$btn-font-family: $base-font-family-sans-serif;
$btn-font-weight: 700;

$btn--secondary-color:					$color-btn;
$btn--secondary-color--dark:		darken($color-btn,15%);

$btn--primary-highlight-bg:			$color-text;
$btn--primary-highlight-text: 	$color-text--inverted;

$btn--secondary-highlight-text:	$color-text--inverted;

// USED FROM _variables.scss
// $base-spacing-unit--xs, --sm, --xl
// $base-border-radius
// $base-border-width
// $base-border

// --------------------------------------------------

@import "../../../nutshell/scss/components/links";

// --------------------------------------------------

%button {
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
}

// btn--primary
a.btn--primary,
span.btn--primary,
button.btn--primary {
	p & {
		margin-bottom: 0;
		text-decoration: none;
	}
}

// [1] button used in a .band--highlight container
div.btn--primary {
  
  a {
    .band--highlight & { // [1]
      background: $btn--primary-highlight-bg;
      color: $btn--primary-highlight-text;
    }
  }
}

a.btn--secondary,
span.btn--secondary,
button.btn--secondary {
  border-radius: $base-border-radius;
  color: $btn--secondary-color;
  border: $base-border-width solid currentColor;
  background-color: transparent;
  
  &:hover {
    color: $btn--secondary-color--dark;
    border-color: $btn--secondary-color--dark;
    background-color: transparent;
  }
  
  .band--highlight & {
    color: $btn--secondary-highlight-text;
    border-color: $btn--secondary-highlight-text;
    
    &:hover {
      opacity: 0.5; 
    }
  }
}

div.btn--secondary a {
  border-radius: $base-border-radius;
  color: $btn--secondary-color;
  border: $base-border-width solid currentColor;
  
  &:hover {
    color: $btn--secondary-color--dark;
    border-color: $btn--secondary-color--dark;
  }
  
  .band--highlight & {
    color: $btn--secondary-highlight-text;
    border-color: $btn--secondary-highlight-text;
    
    &:hover {
      opacity: 0.5; 
    }
  }
}

div.btn--feedback {
	position: fixed;
	right: 0;
	top: $base-spacing-unit--xl;
	transform: rotate(-90deg) translateY($base-spacing-unit--xs);
	transform-origin: bottom right;
	transition: transform 0.1s;
	
	&:hover {
		transform: rotate(-90deg) translateY(0);
	}
	
	a {
		padding: $base-spacing-unit--xs $base-spacing-unit--sm;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border: $base-border;
		border-bottom: 0;
	}
}
