// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/NOTES
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $base-font-family-sans-serif

// --------------------------------------------------
.footer__note {
  font-family: $base-font-family-sans-serif;
  font-weight: bold;
  text-align:  center;
  
  @include media-query(screen-offcanvas) {
	  float: right;
	}
}
