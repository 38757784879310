// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/FORMS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/forms";

// --------------------------------------------------

// PUT YOUR OWN FORM STYLES HERE

input.radio,
input.checkbox {
	vertical-align: top;
}

.widget-radio--list {
	.radio_container {
		> span {
			display: block;
		}
	}
}

.widget-checkbox--list {
	.checkbox_container {
		> span {
			display: block;
		}
	}
}
