// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/EVENTS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss
// $base-spacing-unit, --xs, --lg 
// $base-font-family-sans-serif
// 

// USED FROM _type.scss
// $headings__font-family
// $headings__font-weight

// --------------------------------------------------

@import "../../../nutshell/scss/components/events";

// --------------------------------------------------

.events-list {
	> {
    #{headings()} {
      @extend %h5;
      
      &:after {
        margin-top: $base-spacing-unit--xs;
      }
    }
  }
  
  .header {
	  font-weight: 700;
    font-family: $base-font-family-sans-serif;
  }
  
  .event {
	  #{headings()} {
		  margin-top: 0;
      margin-bottom: 0;

      a {
        color: inherit;
      }
		}
		
		.time {
	    font-family: $base-font-family-sans-serif;
	    line-height: 1.25;
	    margin-bottom: $base-spacing-unit--sm;
	    font-weight: 700;
    }
  }
  
  .empty {
    margin-top: $base-spacing-unit--sm;
    margin-bottom: $base-spacing-unit--sm;
  }
}

// [1] override nutshell default
.events-list--table {
    border-top: $base-border;
    margin-bottom: $base-spacing-unit;
    display: table;
    width: 100%;
    
  .layout_upcoming {
	  display: table-row;
		width: 100%;
    font-family: $base-font-family-sans-serif;
  
    @extend %h3;
    
    a {
      @include padding-default;
      
			color: inherit;
      display: table-cell;
      border-bottom: $base-border;
      padding-bottom: $base-spacing-unit;
    }
    
    .date {
      width: 15%;
      white-space: nowrap;
    }
    
    time {
	    padding-top: 0; // [1]
    }
    
    .headline {
      padding-right: $base-spacing-unit;
      position: relative;
      
      &:after {
        content: "»";
        position: absolute;
        right: 0;
        padding-right: ($base-spacing-unit / 2);
      }
    }
    
    &:after {
      clear: both;
    }
  }
}

// [1] call every headline element (h1-h6)
.events-list--teaser {
  @include make-row;
  
  > *:not(div),   // [1]
  .pagination,
  .empty {
    @include padding-default;
    
    @include media-query(screen-sm) {
      @include make-offset(2);
    
    }
    
    @include media-query(screen-md) {
      @include make-offset(2);
    }
    
    @include media-query(screen-lg) {
      @include make-offset(2);
    }
  }
  
  .header {
    @include padding-default;
    
    clear: both;
    position: relative;

    
    .date:first-line {
		  @extend %h2;
		
		  display: inline-block; 
		  margin-bottom: 0;
		}
    
	  @include media-query(screen-sm) {
	    @include make-col(2);
	    
	    text-align: center;
	    display: block;
	    

	    .date,
	    .month {
	      display: block;
	    }
	    
	    .day {
	      $day-font-size: 0.75rem;
	      
	      font-size: $day-font-size;
	      line-height: 1;
	      display: block;
	      margin-top: $day-font-size * -1;
	    }
	    
	    .month {
		    margin-bottom: 0.5rem;
		    line-height: 1.25;
	    }
	  }
  }

  .layout_teaser,
  .layout_list {
    @include padding-default;
    
    display: block;
    overflow: hidden;
    
    @include media-query(screen-sm) {
      @include make-offset(2);
      @include make-col(10);
    }
    
    @include media-query(screen-md) {
      @include make-offset(2);
    }
    
    #{headings()} {
      @include media-query(screen-sm) {
        margin-top: $base-spacing-unit;
      }
    }
  }
  
  .header + .layout_teaser,
  .header + .layout_list {
    margin-left: 0;
  }
  
  .info {
    @include make-col(10);
  }
  
  .location {
    margin-bottom: 1rem;
    font-style: italic;
  }
}

.events-reader {
  .info {
    font-weight: 700;
    font-family: $base-font-family-sans-serif;
    margin-bottom: $base-spacing-unit--sm;
  }
  
  .ce_text:first-of-type {    
    font-size: $base-font-size--lg;
    line-height: 1.6;
  }
  
  .back {
    font-family: $base-font-family-sans-serif;
    
    a {
      font-weight: bold;
      display: block;  
      text-decoration: none;  
      
      &:before {
        content: "«";
        margin-right: $base-spacing-unit--xs;
      }
    }
  }
}

.events-menu {
  
  > { 
    #{headings()} {
      @extend %h5;
      
      &:after {
        margin-top: $base-spacing-unit--xs;
      }
    }
  }

  a,
  .active {
    text-decoration: none;
    color: inherit;
    font-family: $headings__font-family;
  }
  
  .level_2 a,
  .level_2 span.active {
    vertical-align: middle;
    text-decoration: none;
    
    &:before {
      content: "»";
      margin-right: $base-spacing-unit--xs / 2;
    }
  }
    
  .year {
    > a,
    > .active {
      font-weight: $headings__font-weight;
    }
  }
}

.events-calendar {
  font-family: $base-font-family-sans-serif;
  font-size: $base-font-size--xs;
  
  > {
    #{headings()} {
      @extend %h5;
      
      &:after {
        margin-top: $base-spacing-unit--xs;
        content: "";
        display: block; 
      }
    }
  }
  
  th,
  td {
    padding: $base-spacing-unit--xs / 2;
    text-align: center;
  }
}
