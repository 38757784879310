// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/FAQ
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/faq";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE
.faq-list {
  ul a {
    text-decoration: none;
  }
}
