// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// COMPONENTS/COMMENTS
// --------------------------------------------------

// VARIABLES
$reply-background:	$color-gray-light;
$form-background: 	$color-gray-light;


// USED FROM _variables.scss
// $base-spacing-unit, --sm, --lg
// $base-border-radius


// --------------------------------------------------

@import "../../../nutshell/scss/components/comments";

// --------------------------------------------------

.ce_comments {
	margin-bottom: $base-spacing-unit;
	
	.form {
		padding: $base-spacing-unit--lg $base-spacing-unit--sm;
		background-color: $form-background;
	}
	
	.formbody {
		@include make-width(8);
	}
}
	
.comment_default {
	.info {
		padding-bottom: $base-spacing-unit--sm;
		margin-bottom: $base-spacing-unit--sm;
	}
	
	.reply {
		margin-top: $base-spacing-unit;
		
		@include media-query(screen-sm) {
			padding: $base-spacing-unit;
			background-color: $reply-background;
			border-radius: $base-border-radius;
		}
		
		.info {
			font-style: italic;
		}
	}
}
