// *
// * Erdmann & Freunde
// * SOLO Contao Theme
// * erdmann-freunde.de/themes/solo/
// *

//
// TRUMPS/DOCUMENTATION
// --------------------------------------------------

// VARIABLES


// USED FROM _variables.scss
// $color-gray
// $color-gray-light
// $color-gray-dark
// $base-spacing-unit, --xs, --sm
// $base-border-radius
// $color-band-highlight-background
// $color-band-dark-background-gradient
// $color-band-tint-background
// $base-overlay-background

// --------------------------------------------------

// make grid visible
.show-grid {
	background-color: $color-gray;
	padding-top: $base-spacing-unit;
	margin-bottom: $base-spacing-unit;
	position: relative;
	
	&:before {
		font-size: $base-font-size--xs;
		padding: 0 $base-spacing-unit--xs;
		border-radius: 0 $base-border-radius $base-border-radius 0;
		background-color: $base-overlay-background;
		color: $color-text--inverted;
		position: absolute;
		top: 0;
		content: "screen-xs";
	}
	
	@include media-query(screen-sm) {
		&:before {
			content: "screen-sm";
		}
	}
	
	@include media-query(screen-md) {
		&:before {
			content: "screen-md";
		}
	}
	
	@include media-query(screen-lg) {
		&:before {
			content: "screen-lg";
		}
	}
	
	@include media-query(screen-xl) {
		&:before {
			content: "screen-xl";
		}
	}
	
	[class*="col-"] > * {
		background-color: $color-gray-light;
		padding: $base-spacing-unit--xs;
		border-radius: $base-border-radius;
	}
}

// color palettes
.color-palette {
	border-radius: $base-border-radius;
	padding: $base-spacing-unit--sm;
	margin-bottom: $base-spacing-unit;
	display: flex;
	flex: 1 0 auto;
	
	&--bordered {
		border: 1px solid $color-gray-light;
	}
}

.bg-highlight { background-color: $color-band-highlight-background; }
.bg-dark { background: $color-band-dark-background-gradient; }
.bg-tint { background-color: $color-band-tint-background; }
.bg-overlay {background: $base-overlay-background; }
.bg-gray { background-color: $color-gray; }
.bg-gray-dark { background-color: $color-gray-dark; }

// show/hide feedback button demo/dokumentation
.hide--Dokumentation {
	.link--dokumentation {
		display: none;
	}
}

.hide--Demo {
	.link--demo {
		display: none;
	}
}
